import { useEffect, useState } from "react";

import ActiveCallDetail from "./components/ActiveCallDetail";
import Button from "./components/base/Button";
import Vapi from "@vapi-ai/web";
import { isPublicKeyMissingError } from "./utils";

// Put your Vapi Public Key below.
const vapi = new Vapi("d9bd7b94-4587-4de5-bea1-5fb3970aa446");

const App = () => {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);

  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);

  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } = usePublicKeyInvalid();

  // hook into Vapi events
  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("error", (error) => {
      console.error(error);

      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    // we only want this to fire on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // call start handler
  const startCallInline = () => {
    setConnecting(true);
    // vapi.start(assistantOptions);
    // https://dashboard.vapi.ai/assistants/7d36200c-9879-4adb-9b56-04ad6577f721
    // assistantId
    vapi.start('02369544-e53d-4965-b2e1-b571175d29b7');
  };
  const endCall = () => {
    vapi.stop();
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
    backgroundImage: "linear-gradient(to right, #0e0e2c, #342d4b)", /* Example gradient */
    color: "white",
      }}
    >
      <div className="main-container">
        <header>
          <h1>Voice Demo</h1>
          <p>Click below to start</p>
        </header>
        <br/>             
          {!connected ? (
              <Button
                label="Goddess Aesthetics Clinic Front Desk"
                onClick={startCallInline}
                isLoading={connecting}
              />
            ) : (
              <ActiveCallDetail
                assistantIsSpeaking={assistantIsSpeaking}
                volumeLevel={volumeLevel}
                onEndCallClick={endCall}
              />
            )}
    </div>
      {showPublicKeyInvalidMessage ? <PleaseSetYourPublicKeyMessage /> : null}
      {/*<ReturnToDocsLink />*/}
    </div>
  );
};

// not used
const assistantOptions = {
  name: "Goddess Aesthetics Front Desk",
  firstMessage: "Goddess Aesthetics Clinic speaking, how can I help you?",
  transcriber: {
    provider: "deepgram",
    model: "nova-2",
    language: "en-US",
  },
  voice: {
    provider: "playht",
    voiceId: "jennifer", //default
    //voiceId: "eleanor",    // cannot
    // voiceId: "joanna",    // cannot
  },
  model: {
    provider: "openai",
    model: "gpt-4o",
    messages: [
      {
        role: "system",
        content: `You are a voice assistant for Goddess Aesthetics Clinic, a premier clinic for aesthetic services. 
        The clinic is located at 123 Raffles Place. 
        The hours are 8 AM to 5PM daily. 
        The practicing doctor is Doctor Mary.

        Start by greeting callers and introducing the appointment types available at Goddess Aesthetics Clinic: beauty consultations, cosmetic procedures, and follow-up visits.
        Inform callers that each call is for one appointment type only. If they request multiple appointments, kindly explain that they need to book each one separately.
        Ensure that callers schedule at least one appointment during the call. They can choose any type from our offerings.
        Remember to keep the conversation focused on scheduling appointments. If the discussion veers off-topic, gently guide it back to finalizing the appointment.
        
        1. Ask for their full name.
        2. Ask for the purpose of their appointment.
        3. Request their preferred date and time for the appointment.
        4. Confirm all details with the caller, including the date and time of the appointment.

        End the call by confirming the appointment details and providing a friendly note such as, "Fantastic, your appointment is confirmed for [date and time]. We can't wait to see you!"
        
        - Be sure to be kind of professional and empathetic!
        - Keep all your responses short and simple. Use casual language, phrases like "Umm...", "Well...", and "I mean" are preferred.
        - This is a voice conversation, so keep your responses short, like in a real conversation. Don't ramble for too long.
        `,
      },
    ],
  },
};

const usePublicKeyInvalid = () => {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState(false);

  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
};

const PleaseSetYourPublicKeyMessage = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "25px",
        left: "25px",
        padding: "10px",
        color: "#fff",
        backgroundColor: "#f03e3e",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Is your Vapi Public Key missing? (recheck your code)
    </div>
  );
};

const ReturnToDocsLink = () => {
  return (
    <a
      href="https://docs.vapi.ai"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        top: "25px",
        right: "25px",
        padding: "5px 10px",
        color: "#fff",
        textDecoration: "none",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      return to docs
    </a>
  );
};

export default App;
